@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion component */
.accordion__section {
  display: flex;
  flex-direction: column;
}
.accordion {
  cursor: pointer;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  transition: background-color 0.6s ease;
  border-radius: 0.5rem;
}
.accordion:hover,
.active {
  background-color: #F3F4F6;
}
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}
.rotate {
  transform: rotate(180deg);
}
.accordion__content {
  overflow: hidden;
  transition: max-height 0.6s ease;
}
